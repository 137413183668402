import React  from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import pbicon from './../../assets/PB.png'
import ggicon from './../../assets/gg-icon.svg'

const styles = (theme => ({
      root:{
        margin:'25px 10px',
        display:'table',
        width:'100%'
      },
      box:{
          width:'65px',
          height:'40px',
          textAlign:'Center',
          display:'table-cell',
          verticalAlign:'top',
          '&:hover':{
           
            cursor:'pointer'
        }
      },
      titulo:{
        color:'#3A404B',
        fontSize:'16px',
        display:'block',
        padding:'1px 1px 1px 20px',
        float:'left',
        font:'Medium 16px/36px Avenir',
        fontWeight:'600'

      },
      row:{
          display:'table-row',
          listStyle:'none'
      },
      subtitle:{
        color:'#959CA9',
        font:'Medium 13px/36px Avenir',
        fontSize:'13px',
        display:'block',
        padding:'4px 20px',
        fontWeight:'400',
        '&:first':{
            padding:'0px 1px'
        },
        float:'left',
      },
      info:{
        display:'table-cell'
      },
      playArrow:{
          color:'#2A7EE1',
          margin:'0 5px !important',
      }
  })
)
function ListItemVideo(values){
    
    const {classes,data,configData} = values
    const {title,category,duration,url,product} = data
    const {anchorLinked,onclickedPbIcon} = configData
    
    function onClickPbIcon(link){
        if(typeof onclickedPbIcon !== 'undefined')
        if(onclickedPbIcon !== null)
            onclickedPbIcon(link)
    }
    let valor = null;

    const productPainting = p => {
        let icon = null;
        try {
            if(p === 'PartsBase') {
                valor = 'PB'
                icon = pbicon;
            }
            else if(p === 'GovGistic') {
                valor = 'GG'
                icon = ggicon
            }             
            return icon;
        } catch (error) {
            console.log(error);
        }
    }
   
    return ( 
            <div className={classes.root}>
               {anchorLinked ? 
                    <a className={classes.box} href={url} target='_blank' rel='noopener noreferrer' >
                        <img src={productPainting(product)} alt={valor} onClick={()=>{onClickPbIcon(url)}}></img>
                    </a>
                    :
                    (<div className={classes.box} >
                        <img src={productPainting(product)} alt={valor} onClick={()=>{onClickPbIcon(url)}}></img>
                    </div>)
                } 
            <div className={classes.info}>
                <div className={classes.row}>
                    <div className={classes.titulo}>
                    {title}
                </div>
                   
                 
                </div>
                <div className={classes.row}>
                <div className={classes.subtitle} >
                     {category}
                </div>
                <div className={classes.subtitle} >
                    {
                          ((duration > 60) ?
                          ( Math.floor(duration / 60).toString() +':'+ (duration % 60/100).toFixed(2).toString()).replace('0.','')
                          :
                          '0:' + (Math.floor(duration % 60))) 
                    }
                </div>
                </div>
            </div>
        </div>
    )

}

ListItemVideo.defaultProps = {
    
    data: {
        title:'',
        description:'',
        duration: 0,
        url:'',
        category:[], 
        product: ''
    },
    configData:{
        anchorLinked:false,
        onclickedPbIcon : null
    }
}
ListItemVideo.propTypes = {
   
    children:PropTypes.string,
    data:PropTypes.object.isRequired,
    videoID:PropTypes.string
    
}
const lsItem = withStyles(styles)(ListItemVideo)
export default lsItem