import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import img from './../../assets/bg-abstract.jpg'

const styles = (theme => ({
    root:{
       backgroundImage : `url(${img})`,
       backgroundRepeat:'no-repeat',
       height:'450px',
       position:'relative',
       

    }
})
)
let BannerVideoList = (props) => {
    
    const {classes}  = props
    return(
        <div className={`${classes.root} fixedBackground`}>

        </div>
    )
}

export default withStyles(styles)(BannerVideoList)