import React from 'react'
import { withStyles } from '@material-ui/core/styles'



const styles = (theme => ({
    root:{
      color:'white',
      position:'absolute',
      left:'0',
      top:'20px',
      '&h1,h2,h3,h4,h5,h6':{
        margin:'10px'
      },
      zIndex:'999',
     
     
    },
    title1:{
        fontSize:'21px',
        font: 'Black 21px/21px Avenir'
        
    }, title2:{
        fontSize:'34px',
        fontWeight:'900'
        
    }, 
    title3:{
        fontSize:'16px',
        color:'#CDD6E0',
        font:'Medium 16px/21px Avenir'

    }

})
)
let Textheader = (props) => {
   const {classes} = props
   return(
        <div className={`${classes.root} TitleHeader`}>
            <h4 className={classes.title1}>
                PartsBase
            </h4>
            <h2 className={classes.title2}>
                Educational
            </h2>
            <h6 className={classes.title3}>
                Video Training Series
            </h6>
        </div>
   )
}

export default withStyles(styles)(Textheader)