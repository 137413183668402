import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
const Loadergift = ({displayVal})=> {
    return (
         <FontAwesomeIcon icon={faSpinner} style={{display:displayVal}} className='spinner'  spin />
    )
 }
 Loadergift.defaulfProps = {
    displayVal:'block'
 }
 export default Loadergift