import React, {Component} from 'react'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import './modal.css'


class ModalWindow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: this.props.isOpen
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({
            modal: nextProps.isOpen,
        })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
        if(typeof this.props.onClose !== 'undefined')
            this.props.onClose()
    }

    render() {
        const {children} = this.props
        return (
            <div>
                <Modal className='modalVideos' isOpen={this.state.modal} toggle={this.toggle}  size={this.props.size ? this.props.size : 'lg'}>
                    <ModalHeader toggle={this.toggle} className='modal-window'>
                        <span className='modalTitle'><i className={this.props.icon} style={{color: 'rgb(74, 144, 226)', marginRight: '5px'}}></i>{this.props.title}</span>
                    </ModalHeader>
                    <ModalBody>
                        {
                           children
                        }
                        
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default ModalWindow