import React from 'react'
import Bannervideolist from './../../components/atoms/BannerHeader'
import { withStyles } from '@material-ui/core/styles'

const styles = themes => ({
    root:{
        position:'relative',
        background:'transparent linear-gradient(180deg, #E6EDF4 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box',
      
    }
})
const BasePage = (props)=>{
    const {classes} = props
    return( 
    <div className={classes.root}>
        <Bannervideolist />
    </div>
 )
}

export default withStyles(styles)(BasePage)