const getVideos = async (filters) => {
    const awsGateway = process.env.REACT_APP_AWS_GATEWAY === undefined
        ? process.env.AWS_GATEWAY
        : process.env.REACT_APP_AWS_GATEWAY
    let response = {
        ok:false,
        message:'',
        data:null
    }
    try{
        function concatUrl(url,params){
            const urlParams = new URL(url)
            Object.keys(params).forEach(key => urlParams.searchParams.append(key,params[key]))
            return urlParams
        }
        let {orderByAsc,searchText,filterBy,indexPage,product} = filters
        const url = awsGateway + '/media/video'
     
        const vals = {
            search : searchText,
            page:indexPage,
            sortdirection:!orderByAsc?'desc':'asc',
            sortby:filterBy,
            product: product
        }
        let header  = new Headers()
        header.append('Content-Type','application/json')
        const urlRequest = concatUrl(url,vals)
        await fetch(urlRequest.href,{
        method:'GET',
        headers:header
       
       }).then(async (res) => {

           response.ok = res.ok
           response.data = await res.json()
               
          
        }).catch(error =>{ 
            
            response.message = error
        })
    }
    catch(error){
      
        response.message = error
    }
     return await response;
    
}  
export default getVideos 