import React from 'react';
import VideosTraining from './scenes/VideosTraining'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <VideosTraining />
  );
}

export default App;
