import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import iconFilter from '../../assets/product-filter.svg';

const styles = (teme) => ({
  root: {
    color: '#1B2437',
    fontSize: '14px',
    //float:'left',
    fontWeight: '500',
    width: '240px',
    fontFamily: 'Avenir !important',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  product_filter: {
    border: '1px solid #959CA9',
    display: 'inline-block',
    margin: '10px 0px 10px 7px',
    padding: '2px 0px 2px 5px',
    width: '160px',
    height: '36px',
    borderRadius: '4px',
    fontFamily: 'Avenir !important',
  },
  input: {
    color: '#676F7E',
    border: 'none',
    fontWeight: '500',
    margin: '5px 2px',
    backgroundColor: 'rgba(255,255,255,0)',
    width: 'calc(100% - 35px)',
    fontFamily: 'Avenir !important',
  },
  span: {
    fontFamily: 'Avenir !important',
  },
  spanIcon: {
    height: '18.67px',
    marginTop: '0.35em',
  },
  icon: {
    transition: 'transform 1s linear',
    position: 'absolute',
  },
});

const ProductSelection = (props) => {
  const { classes, onSelected } = props;
  const [products, setProducts] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  let productsFirstTime = props.productsFirstTime;

  const setOptionToRefresh = (event) => {
    try {
      if (event) {
        setSelectedValue(event.target.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchProductToRefresh = () => {
    try {
      if (onSelected) {
        onSelected(selectedValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const settingProductData = () => {
    try {
      setProducts([
        {
          name: 'All products',
          description: 'All products',
        },
        {
          name: 'PartsBase',
          description: 'PartsBase',
        },
        {
          name: 'Govgistic',
          description: 'Government Data',
        },
      ]);
      setSelectedValue(window.location.pathname === '/GD' ? 'Govgistic' : 'PartsBase');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    settingProductData();
    productsFirstTime = true;
  }, []);

  useEffect(() => {
    if (!productsFirstTime) {
      searchProductToRefresh();
    }
  }, [selectedValue]);

  return (
    <div className={`${classes.root} product-select`}>
      <span className={`${classes.span} product-span`}>Filter for</span>
      <div className={classes.product_filter}>
        <select
          id='selectFilter'
          className={classes.input}
          onChange={(event) => setOptionToRefresh(event)}
          value={selectedValue}>
          {products
            ? products.map((p, index) => {
                return (
                  <option key={index} value={p.name}>
                    {p.description}
                  </option>
                );
              })
            : 'No enabled products...'}
        </select>
        <span
          className={`${classes.icon} ${classes.spanIcon} cursorPointer`}
          onClick={searchProductToRefresh}>
          <img
            src={iconFilter}
            alt=''
            style={{ width: '20px', height: '20px', margin: '0px 2px 5px 0px' }}
          />
        </span>
      </div>
    </div>
  );
};

export default React.memo(withStyles(styles)(ProductSelection));
