import React from 'react'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { withStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'

const styles = (theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
  },
  
  inputRoot: {
    border:'none',
    width: 'calc(100% - 32px)',
    height: '36px',
    padding:'3px 5px 3px 14px',
    color:'#676F7E'
  },
  inputInput: {
    borderRadius:'4px',
    border:'1px solid #BABABA',
    transition: theme.transitions.create('width'),
    width: '100%',
    color:'#676F7E',
    fontWeight:'600',
    fontSize:'14px',
    backgroundColor:'white',
    
  },
  iconSearch:{
    fontSize:'1.3rem',
    "&:hover":{cursor:'pointer'}
    
  }
})
)

function InputSearch(classes,onResults,cleanText,handleData){

    function onSelectTextFromIcon(){
      if(onResults) {
        onResults(handleData.textValue)
        document.getElementById('search-video').value = ''
        if(cleanText) {
          cleanText('')
        }
      } 
    }
    function onSelectText(e){
        const enter = e.keyCode === 13;
        handleData.textValue = e.target.value
        if(enter){
              if(onResults) {
                onResults(handleData.textValue)
                document.getElementById('search-video').value = ''
                if(cleanText) {
                  cleanText('')
                }
              }
        }
        
        return enter
    }

    return(
        <div className={classes.root}>
             <div className = {classes.inputInput}>
                <input id='search-video' className={classes.inputRoot} 
                    onKeyUp={(event) => onSelectText(event)}
                    placeholder="Search"
                    type='text'
                />
                <SearchIcon className={classes.iconSearch} onClick = { onSelectTextFromIcon } />
             </div>
        </div>
    )

}
function SearchAppBar(props) {
  const { classes,onResults,cleanText,handleData } = props
  return InputSearch(classes,onResults,cleanText,handleData)
}
SearchAppBar.defaultProps = {
   
    onResults:null,
    handleData:{
      textValue:''
    }
}


export default withStyles(styles)(SearchAppBar)
