import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconFilter from './../../assets/sort-24px.svg';

const styles = (theme) => ({
	root: {
		color: '#1B2437',
		fontSize: '14px',
		//float: 'right',
		fontWeight: '500',
		width: '220px',
		fontFamily: 'Avenir !important',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	input: {
		color: '#676F7E',
		border: 'none',
		fontWeight: '500',
		margin: '5px 2px',
		backgroundColor: 'rgba(255,255,255,0)',
		width: 'calc(100% - 35px)',
		fontFamily: 'Avenir !important',
	},
	filter: {
		border: '1px solid #959CA9',
		display: 'inline-block',
		margin: '10px 0px 10px 7px',
		padding: '2px 0px 2px 5px',
		width: '139px',
		height: '36px',
		borderRadius: '4px',
		fontFamily: 'Avenir !important',
	},
	orderBy: {
		transform: 'scaleY(1)',
		margin: '5px 2px 0px 0px !important',
	},
	descendingBy: {
		transform: 'scaleY(-1)',
		margin: '0px 2px 5px 0 !important',
	},
	icon: {
		transition: 'transform 1s linear',
		position: 'absolute',
	},
});
function PbFilter(props) {
	const { classes, filters } = props;
	const [optionSelected, setOptionSelected] = useState('Title');
	const [order, setOrder] = useState(true);
	const { onSelected } = props;

	useEffect(() => {
		if (typeof onSelected !== 'undefined') {
			if (onSelected !== null) {
				onSelected(optionSelected, order);
			}
		}
	}, [optionSelected]);

	function setOptionToRefresh(event) {
		setOptionSelected(event.target.value);
	}
	function setOrderToRefresh() {
		setOrder(!order);
		if (typeof onSelected !== 'undefined') {
			if (onSelected !== null) {
				onSelected(optionSelected, !order);
			}
		}
	}
	return (
		<div className={`${classes.root} filter-select`}>
			<span>Sort by</span>
			<div className={classes.filter}>
				<select
					id='selectFilter'
					className={classes.input}
					onChange={(event) => setOptionToRefresh(event)}
					value={optionSelected}
				>
					{typeof filters !== 'undefined'
						? props.filters.map((val, index) => {
								return (
									<option key={index} value={val.Value}>
										{val.Description}
									</option>
								);
						  })
						: 'There is not filters'}
				</select>
				<span
					className={`${classes.icon} ${
						order ? classes.descendingBy : classes.orderBy
					} cursorPointer`}
					onClick={() => setOrderToRefresh()}
				>
					<img
						src={IconFilter}
						alt=''
						style={{ width: '20px', height: '20px', margin: '0px 2px 5px 0px' }}
					/>
				</span>
			</div>
		</div>
	);
}
PbFilter.propTypes = {
	filters: PropTypes.array,
};
PbFilter.defaultProps = {
	filters: [
		{
			Description: 'Duration',
			Value: 'Duration',
			Selected: false,
		},
		{
			Description: 'Upload Time',
			Value: 'UploadTime',
			Selected: true,
		},
		{
			Description: 'Title',
			Value: 'Title',
			Selected: false,
		},
	],
	defaultValue: '',
};
const fsStyle = withStyles(styles)(PbFilter);
export default fsStyle;
