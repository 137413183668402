import React from 'react'
import Basepage from './../components/organism/BasePage'
import Videocontroller from './../components/organism/VideosController'
const VideosTraining =() =>{
    return(
        <div>
           <Basepage />
           <Videocontroller/>
        </div>
     
    )
}
export default VideosTraining